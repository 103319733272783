export let settingRoutes = {
	meta: {
		menuTitle: "settings.lang_settings",
		icon: "pe-7s-tools",
	},
	childs: [],
	groups: [
		{
			meta: {
				menuTitle: "settings.lang_nav_settings_cashierSettings",
				icon: "lnr-store",
				permission: {},
			},
			childs: [
				{
					path: "/settings/cashierSettings/layoutSettings",
					name: "settings.cashierSettings.layoutSettings",
					meta: {
						siteTitle: "settings.lang_nav_design",
						menuTitle: "settings.lang_nav_design",
						layout: "default",
						icon: "",
						permission: {
							requiredModule: 0,
							requiredPermission: "managePosGridLayout",
							hybridDisplay: "",
						},
					},
					component: () => import("../views/settings/cashier/Layout"),
				},
				{
					path: "/settings/cashierSettings/layoutSettings/posGridTemplate/:id",
					name: "settings.cashierSettings.layoutSettings.posGridTemplate",
					props: true,
					meta: {
						hideInNavigation: true,
						siteTitle: "settings.lang_nav_design",
						menuTitle: "settings.lang_nav_design",
						layout: "default",
						icon: "",
						permission: {
							requiredModule: 0,
							requiredPermission: "",
							hybridDisplay: "",
						},
					},
					component: () => import("../views/settings/cashier/LayoutPOSGridTemplate"),
				},

				//begin table planer
				{
					path: "/settings/cashierSettings/roomFloor/abstract/:id",
					name: "settings.cashierSettings.roomFloor.abstract",
					meta: {
						siteTitle: "room floor",
						menuTitle: "room floor",
						layout: "default",
						icon: "",
						hideInNavigation: true,
						permission: {
							requiredModule: 37,
							requiredPermission: "roomPlanCreateEdit",
							hybridDisplay: "",
						},
					},
					component: () => import("../views/settings/cashier/RoomFloor/RoomFloorEditor"),
				},
				{
					path: "/settings/cashierSettings/roomPlaner",
					name: "settings.cashierSettings.roomPlaner",
					meta: {
						siteTitle: "settings.lang_nav_settings_roomplaner",
						menuTitle: "settings.lang_nav_settings_roomplaner",
						layout: "default",
						icon: "",
						permission: {
							requiredModule: 37,
							requiredPermission: "roomPlanCreateEdit",
							hybridDisplay: "",
						},
					},
					component: () => import("../views/settings/cashier/TablePlanner/TablePlaner"),
				},
				{
					path: "/settings/cashierSettings/roomPlaner/create",
					name: "settings.cashierSettings.roomPlaner.create",
					meta: {
						siteTitle: "create room",
						menuTitle: "settings.lang_nav_settings_roomplaner",
						hideInNavigation: true,
						layout: "default",
						icon: "",
						permission: {
							requiredModule: 37,
							requiredPermission: "roomPlanCreateEdit",
							hybridDisplay: "",
						},
					},
					component: () => import("../views/settings/cashier/TablePlanner/TablePlanerCreate"),
				},
				{
					path: "/settings/cashierSettings/roomPlaner/edit/:id",
					name: "settings.cashierSettings.roomPlaner.edit",
					meta: {
						siteTitle: "edit room",
						menuTitle: "settings.lang_nav_settings_roomplaner",
						layout: "default",
						icon: "",
						hideInNavigation: true,
						permission: {
							requiredModule: 37,
							requiredPermission: "roomPlanCreateEdit",
							hybridDisplay: "",
						},
					},
					component: () => import("../views/settings/cashier/TablePlanner/TablePlanerEdit"),
				},
				{
					path: "/settings/cashierSettings/tablesQr",
					name: "settings.cashierSettings.tablesQr",
					meta: {
						siteTitle: "Export tables qr",
						menuTitle: "tablebee.lang_qr_code_generator",
						layout: "default",
						icon: "",
						hideInNavigation: false,
						permission: {
							requiredModule: 37,
							requiredPermission: "roomPlanCreateEdit",
							hybridDisplay: "",
						},
					},
					component: () => import("../views/settings/cashier/TablePlanner/TableQrView"),
				},
				//End Table planer
				{
					///settings/cashierSettings
					path: "/settings/cashierSettings/courseSettings",
					name: "settings.cashierSettings.courseSettings",
					meta: {
						siteTitle: "erp.lang_nav_settings_gangSteuerung",
						menuTitle: "erp.lang_nav_settings_gangSteuerung",
						layout: "default",
						icon: "",
						permission: {
							requiredModule: 30,
							requiredPermission: "courses",
							hybridDisplay: "",
						},
					},
					component: () => import("../views/settings/cashier/CourseSettings"),
				},
				//start cashier modules
				{
					path: "/settings/cashierSettings/cashiermodules",
					name: "settings.cashierSettings.cashiermodules",
					meta: {
						siteTitle: "settings.lang_cashierModules",
						menuTitle: "settings.lang_cashierModules",
						layout: "default",
						icon: "",
						permission: {
							requiredModule: 0,
							requiredPermission: "",
							hybridDisplay: "Server",
						},
					},
					component: () => import("../views/settings/cashier/cashiermodules/cashiermodules"),
				},
				{
					path: "/settings/cashierSettings/cashiermoduleEdit/:id",
					name: "settings.cashierSettings.cashiermoduleEdit",
					meta: {
						layout: "default",
						icon: "",
						hideInNavigation: true,
						permission: {
							requiredModule: 0,
							requiredPermission: "",
							hybridDisplay: "",
						},
					},
					component: () => import("../views/settings/cashier/cashiermodules/CashierModuleEdit"),
				},
				//end cashier modules
				{
					path: "/settings/cashierSettings/customerDisplay",
					name: "settings.cashierSettings.customerDisplay",
					meta: {
						siteTitle: "settings.lang_custDisplay",
						menuTitle: "settings.lang_custDisplay",
						layout: "default",
						icon: "",
						permission: {
							requiredModule: 29,
							requiredPermission: "manageCustomerDisplay",
							hybridDisplay: "",
						},
					},
					component: () => import("../views/settings/Customerdisplay.vue"),
				},
				{
					path: "/settings/cashierSettings/customerDisplayTemplate/:id",
					name: "settings.cashierSettings.customerDisplayTemplate",
					props: true,
					meta: {
						siteTitle: "settings.lang_custDisplayTemplate",
						menuTitle: "settings.lang_custDisplayTemplate",
						layout: "default",
						icon: "",
						hideInNavigation: true,
						permission: {
							requiredModule: 29,
							requiredPermission: "manageCustomerDisplay",
							hybridDisplay: "",
						},
					},
					component: () => import("../views/settings/CustomerdisplayTemplate.vue"),
				},
				{
					path: "/settings/cashierSettings/priceCheckTerminal",
					name: "settings.cashierSettings.priceCheckTerminal",
					meta: {
						siteTitle: "settings.lang_priceCheckTerminal",
						menuTitle: "settings.lang_priceCheckTerminal",
						layout: "default",
						icon: "",
						permission: {
							requiredModule: 86,
							requiredPermission: "manage_priceCheckerSettings",
							hybridDisplay: "",
						},
					},
					component: () => import("../views/settings/cashier/PriceCheckTerminal.vue"),
				},
				{
					path: "/settings/cashierSettings/priceCheckTerminalTemplate/:id",
					name: "settings.cashierSettings.priceCheckTerminalTemplate",
					props: true,
					meta: {
						siteTitle: "settings.lang_priceCheckTerminalTemplate",
						menuTitle: "settings.lang_priceCheckTerminalTemplate",
						layout: "default",
						icon: "",
						hideInNavigation: true,
						permission: {
							requiredModule: 86,
							requiredPermission: "manage_priceCheckerSettings",
							hybridDisplay: "",
						},
					},
					component: () => import("../views/settings/cashier/PriceCheckTerminalTemplate.vue"),
				},
				{
					path: "/settings/cashierSettings/kitchenmonitor",
					name: "settings.cashierSettings.kitchenmonitor",
					meta: {
						siteTitle: "settings.lang_kitchenmonitor",
						menuTitle: "settings.lang_kitchenmonitor",
						layout: "default",
						icon: "",
						permission: {
							requiredModule: 10,
							requiredPermission: "kitchenmonitor_overall",
							hybridDisplay: "",
						},
					},
					component: () => import("../views/settings/Kitchenmonitor.vue"),
				},
				{
					path: "/settings/cashierSettings/pickupmonitor",
					name: "settings.cashierSettings.pickupmonitor",
					meta: {
						siteTitle: "settings.lang_pickupMonitor",
						menuTitle: "settings.lang_pickupMonitor",
						layout: "default",
						icon: "",
						permission: {
							requiredModule: 10,
							requiredPermission: "kitchenmonitor_overall",
							hybridDisplay: "",
						},
					},
					component: () => import("../views/settings/Pickupmonitor.vue"),
				},
				{
					path: "/settings/cashierSettings/orderbonarchive",
					name: "settings.cashierSettings.orderbonarchive",
					meta: {
						siteTitle: "settings.lang_orderbonarchive",
						menuTitle: "settings.lang_orderbonarchive",
						layout: "default",
						icon: "",
						permission: {
							requiredModule: 2,
							requiredPermission: "",
							hybridDisplay: "",
						},
					},
					component: () => import("../views/settings/cashier/OrderbonArchive.vue"),
				},
				{
					path: "/settings/cashierSettings/displayorderbonarchive/:id",
					name: "settings.cashierSettings.displayorderbonarchive",
					meta: {
						hideInNavigation: true,
						siteTitle: "settings.lang_orderbonarchive",
						menuTitle: "settings.lang_orderbonarchive",
						layout: "default",
						icon: "",
						permission: {
							requiredModule: 2,
							requiredPermission: "",
							hybridDisplay: "",
						},
					},
					component: () => import("../views/settings/cashier/DisplayOrderbonArchive.vue"),
				},
				{
					path: "/settings/printerSettings/pickupnames",
					name: "settings.printerSettings.pickupnames",
					meta: {
						layout: "default",
						siteTitle: "settings.lang_pickUpNames",
						menuTitle: "settings.lang_pickUpNames",
						icon: "",
						permission: {
							requiredModule: 0,
							requiredPermission: "managePickUpNames",
							hybridDisplay: "",
						},
					},
					component: () => import("../views/settings/cashier/PickupNames"),
				},
				{
					path: "/settings/cashierSettings/additionalstatistics",
					name: "settings.cashierSettings.additionalstatistics",
					meta: {
						layout: "default",
						siteTitle: "generic.lang_AdditionalStatistics",
						menuTitle: "generic.lang_AdditionalStatistics",
						icon: "",
						permission: {
							requiredModule: 0,
							requiredPermission: "",
							hybridDisplay: "",
						},
					},
					component: () => import("../views/settings/cashier/additionalstatistics/AdditionalStatistics"),
				},
				{
					path: "/settings/cashierSettings/selfcheckout",
					name: "settings.cashierSettings.selfcheckout",
					meta: {
						layout: "default",
						siteTitle: ('generic.lang_nav_retail_selfcheckout'),
						menuTitle: ('generic.lang_nav_retail_selfcheckout'),
						icon: "",
						permission: {
							requiredModule: 85,
							requiredPermission: "access_self_service_pos",
							hybridDisplay: "",
						},
					},
					component: () => import("../views/settings/cashier/SelfCheckoutSettings"),
				},
				{
					path: "/settings/cashierSettings/additionalstatistics/create",
					name: "settings.cashierSettings.additionalstatistics.create",
					meta: {
						layout: "default",
						siteTitle: "generic.lang_AdditionalStatistics",
						menuTitle: "generic.lang_AdditionalStatistics",
						icon: "",
						hideInNavigation: true,
						permission: {
							requiredModule: 0,
							requiredPermission: "",
							hybridDisplay: "",
						},
					},
					component: () =>
						import("../views/settings/cashier/additionalstatistics/CreateAdditionalStatistics"),
				},
				{
					path: "/settings/cashierSettings/additionalstatistics/edit/:uuid",
					name: "settings.cashierSettings.additionalstatistics.edit",
					meta: {
						layout: "default",
						siteTitle: "generic.lang_AdditionalStatistics",
						menuTitle: "generic.lang_AdditionalStatistics",
						icon: "",
						hideInNavigation: true,
						permission: {
							requiredModule: 0,
							requiredPermission: "",
							hybridDisplay: "",
						},
					},
					component: () => import("../views/settings/cashier/additionalstatistics/EditAdditionalStatistics"),
				},

				{
					path: "/settings/printerSettings/pickupnames/create",
					name: "settings.printerSettings.pickupnames.create",
					meta: {
						layout: "default",
						siteTitle: "settings.lang_pickUpNames",
						menuTitle: "settings.lang_pickUpNames",
						icon: "",
						hideInNavigation: true,
						permission: {
							requiredModule: 0,
							requiredPermission: "managePickUpNames",
							hybridDisplay: "",
						},
					},
					component: () => import("../views/settings/cashier/PickupNamesCreate"),
				},
				/*     {
                         path: '/settings/cashierSettings/flWaregroups',
                         name: 'settings.cashierSettings.flWaregroups',
                         meta: {
                             siteTitle: ('settings.lang_nav_flashorder_waregroups'),
                             menuTitle: ('settings.lang_nav_flashorder_waregroups'),
                             layout: 'default',
                             icon: '',
                             permission: {
                                 requiredModule: 27,
                                 requiredPermission: "",
                                 hybridDisplay: "Server"
                             }
                         },
                         component: () => import('../views/erp/AllergensComponent.vue')
                     }*/
			],
		},
		{
			meta: {
				menuTitle: "settings.lang_printerSettings",
				icon: "pe-7s-print",
				permission: {},
			},
			childs: [
				{
					path: "/settings/printerSettings/printerManager",
					name: "settings.printerSettings.printerManager",
					meta: {
						siteTitle: "settings.lang_nav_settings_printermanager",
						menuTitle: "settings.lang_nav_settings_printermanager",
						layout: "default",
						icon: "",
						permission: {
							requiredModule: 0,
							requiredPermission: "managePrinters",
							hybridDisplay: "",
						},
					},
					component: () => import("../views/settings/printerSettings/PrintManager"),
				},
				{
					path: "/settings/printerSettings/printerManager/createPrinter",
					name: "settings.printerSettings.printerManager.createPrinter",
					meta: {
						hideInNavigation: true,
						siteTitle: "settings.lang_nav_settings_printermanager",
						menuTitle: "settings.lang_nav_settings_printermanager",
						layout: "default",
						icon: "",
						permission: {
							requiredModule: 0,
							requiredPermission: "managePrinters",
							hybridDisplay: "",
						},
					},
					component: () => import("../views/settings/printerSettings/PrintManagerCreatePrinter.vue"),
				},
				{
					props: true,
					path: "/settings/printerSettings/printerManager/editPrinter/:id",
					name: "settings.printerSettings.printerManager.editPrinter",
					meta: {
						hideInNavigation: true,
						siteTitle: "settings.lang_nav_settings_printermanager",
						menuTitle: "settings.lang_nav_settings_printermanager",
						layout: "default",
						icon: "",
						permission: {
							requiredModule: 0,
							requiredPermission: "managePrinters",
							hybridDisplay: "",
						},
					},
					component: () => import("../views/settings/printerSettings/PrintManagerEditPrinter.vue"),
				},
				{
					path: "/settings/printerSettings/receiptTmpl",
					name: "settings.printerSettings.receiptTmpl",
					meta: {
						siteTitle: "settings.lang_receiptTmpl",
						menuTitle: "settings.lang_receiptTmpl",
						layout: "default",
						icon: "",
						permission: {
							requiredModule: 0,
							requiredPermission: "managePrinters",
							hybridDisplay: "",
						},
					},
					component: () => import("../views/settings/printerSettings/templates/index"),
				},
				{
					path: "/settings/printerSettings/receiptTmpl/details/:id",
					name: "settings.printerSettings.receiptTmpl.details",
					meta: {
						hideInNavigation: true,
						layout: "default",
						icon: "",
						permission: {
							requiredModule: 0,
							requiredPermission: "managePrinters",
							hybridDisplay: "",
						},
					},
					component: () => import("../views/settings/printerSettings/templates/TemplateEdit"),
				},
				/* {
                    path: '/settings/printerSettings/invoiceTmpl',
                    name: 'settings.printerSettings.invoiceTmpl',
                    meta: {
                        siteTitle: ('settings.lang_invoiceTmpl'),
                        menuTitle: ('settings.lang_invoiceTmpl'),
                        layout: 'default',
                        icon: '',
                        permission: {
                            requiredModule: 0,
                            requiredPermission: "managePrinters",
                            hybridDisplay: ""
                        }
                    },
                    component: () => import('../views/common/404.vue')
                }*/
			],
		},
		{
			meta: {
				menuTitle: "settings.lang_basicSettings",
				icon: "pe-7s-tools",
				permission: {},
			},
			childs: [
				{
					path: "/settings/basicSettings/displayUsers",
					name: "settings.basicSettings.displayUsers",
					meta: {
						siteTitle: "settings.lang_users",
						menuTitle: "settings.lang_users",
						layout: "default",
						icon: "",
						permission: {
							requiredModule: 0,
							requiredPermission: "manageUsers",
							hybridDisplay: "",
						},
					},
					component: () => import("../views/settings/basic/users/SearchUsers"),
				},
				{
					path: "/settings/basicSettings/createUsers/:id?",

					name: "settings.basicSettings.createUsers",
					meta: {
						siteTitle: "settings.lang_user_edit",
						menuTitle: "settings.lang_user_edit",
						layout: "default",
						icon: "",
						hideInNavigation: true,
						permission: {
							requiredModule: 0,
							requiredPermission: "manageUsers",
							hybridDisplay: "Server",
						},
					},
					component: () => import("../views/settings/basic/users/CreateUser"),
				},
				{
					path: "/settings/basicSettings/editUsers/:id",
					name: "settings.basicSettings.editUsers",
					props: true,
					meta: {
						siteTitle: "settings.lang_user_edit",
						menuTitle: "settings.lang_user_edit",
						layout: "default",
						icon: "",
						hideInNavigation: true,
						permission: {
							requiredModule: 0,
							requiredPermission: "manageUsers",
							hybridDisplay: "",
						},
					},
					component: () => import("../views/settings/basic/users/EditUser"),
				},
				//CHANGE PASSWORD
				{
					path: "/settings/basicSettings/changePassword",
					name: "settings.basicSettings.changePassword",
					meta: {
						siteTitle: "settings.lang_nav_settings_password",
						menuTitle: "settings.lang_nav_settings_password",
						layout: "default",
						icon: "",
						hideInNavigation: true,
						permission: {
							requiredModule: 0,
							requiredPermission: "",
							hybridDisplay: "",
						},
					},
					component: () => import("../views/settings/ChangePassword"),
				},
				//start of roles
				{
					path: "/settings/basicSettings/roles",
					name: "settings.basicSettings.roles",
					meta: {
						siteTitle: "settings.lang_roles",
						menuTitle: "settings.lang_roles",
						layout: "default",
						icon: "",
						permission: {
							requiredModule: 0,
							requiredPermission: "manageUserRoles",
							hybridDisplay: "Server",
						},
					},
					component: () => import("../views/settings/basic/roles/Role"),
				},
				{
					path: "/settings/basicSettings/roles/create",
					name: "settings.basicSettings.roles.create",
					meta: {
						hideInNavigation: true,
						layout: "default",
						icon: "",
						permission: {
							requiredModule: 0,
							requiredPermission: "manageUserRoles",
							hybridDisplay: "Server",
						},
					},
					component: () => import("../views/settings/basic/roles/RoleCreate"),
				},
				{
					path: "/settings/basicSettings/roles/edit/:id",
					name: "settings.basicSettings.roles.edit",
					meta: {
						hideInNavigation: true,
						layout: "default",
						icon: "",
						permission: {
							requiredModule: 0,
							requiredPermission: "manageUserRoles",
							hybridDisplay: "Server",
						},
					},
					component: () => import("../views/settings/basic/roles/RoleEdit"),
				},
				//end of roles
				//start of user groups
				{
					path: "/settings/basicSettings/permissions",
					name: "settings.basicSettings.permissions",
					meta: {
						siteTitle: "settings.lang_nav_usergroups",
						menuTitle: "settings.lang_nav_usergroups",
						layout: "default",
						icon: "",
						permission: {
							requiredModule: 0,
							requiredPermission: "managePermGroups",
							hybridDisplay: "Server",
						},
					},
					component: () => import("../views/settings/basic/usergroup/UserGroup"),
				},
				{
					path: "/settings/basicSettings/permissions/create",
					name: "settings.basicSettings.permissions.create",
					meta: {
						hideInNavigation: true,
						layout: "default",
						icon: "",
						permission: {
							requiredModule: 0,
							requiredPermission: "managePermGroups",
							hybridDisplay: "Server",
						},
					},
					component: () => import("../views/settings/basic/usergroup/UserGroupCreate"),
				},
				{
					path: "/settings/basicSettings/permissions/edit/:id",
					name: "settings.basicSettings.permissions.edit",
					meta: {
						hideInNavigation: true,
						layout: "default",
						icon: "",
						permission: {
							requiredModule: 0,
							requiredPermission: "managePermGroups",
							hybridDisplay: "Server",
						},
					},
					component: () => import("../views/settings/basic/usergroup/UserGroupEdit"),
				}, //end of user groups
				//start of main settings
				{
					path: "/settings/basicSettings/mainSettings",
					name: "settings.basicSettings.mainSettings",
					meta: {
						siteTitle: "generic.lang_nav_systemSettings",
						menuTitle: "generic.lang_nav_systemSettings",
						layout: "default",
						icon: "",
						permission: {
							requiredModule: 0,
							requiredPermission: "manageMainSettings",
							hybridDisplay: "Server",
						},
					},
					component: () => import("../views/settings/sysetmdata/SystemSettings.vue"),
				},
				{
					path: "/settings/basicSettings/appSettings",
					name: "settings.basicSettings.appSettings",
					meta: {
						siteTitle: "settings.lang_appSettings",
						menuTitle: "settings.lang_appSettings",
						layout: "default",
						icon: "",
						permission: {
							requiredModule: 0,
							requiredPermission: "manageMainSettings",
							hybridDisplay: "Server",
						},
					},
					component: () => import("../views/settings/appsettings/AppSettings.vue"),
				},
				{
					path: "/settings/basicSettings/mainSettings/payment/ecterminal/create",
					name: "settings.basicSettings.mainSettings.payment.ecTerminal.create",
					meta: {
						siteTitle: "EC Terminal",
						menuTitle: "EC Terminal",
						layout: "default",
						icon: "",
						hideInNavigation: true,
						permission: {
							requiredModule: 0,
							requiredPermission: "manageMainSettings",
							hybridDisplay: "Server",
						},
					},
					component: () => import("../views/settings/sysetmdata/CreateECTerminal"),
				},
				{
					path: "/settings/basicSettings/mainSettings/payment/ecTerminal/edit/:id",
					name: "settings.basicSettings.mainSettings.payment.ecTerminal.edit",
					meta: {
						siteTitle: "Adyen Terminal",
						menuTitle: "adyen Terminal",
						layout: "default",
						icon: "",
						hideInNavigation: true,
						permission: {
							requiredModule: 0,
							requiredPermission: "manageMainSettings",
							hybridDisplay: "Server",
						},
					},
					component: () => import("../views/settings/sysetmdata/EditECTerminal"),
				},
				{
					path: "/settings/basicSettings/mainSettings/payment/adyenterminal/edit/:id",
					name: "settings.basicSettings.mainSettings.payment.adyenterminal.edit",
					meta: {
						siteTitle: "Adyen Terminal",
						menuTitle: "adyen Terminal",
						layout: "default",
						icon: "",
						hideInNavigation: true,
						permission: {
							requiredModule: 0,
							requiredPermission: "manageMainSettings",
							hybridDisplay: "Server",
						},
					},
					component: () => import("../views/settings/sysetmdata/EditAdyenECTerminal"),
				},
				{
					path: "/settings/basicSettings/mainSettings/payment/ccvterminal/edit/:id",
					name: "settings.basicSettings.mainSettings.payment.ccvterminal.edit",
					meta: {
						siteTitle: "CCV Terminal",
						menuTitle: "ccv Terminal",
						layout: "default",
						icon: "",
						hideInNavigation: true,
						permission: {
							requiredModule: 0,
							requiredPermission: "manageMainSettings",
							hybridDisplay: "Server",
						},
					},
					component: () => import("../views/settings/sysetmdata/EditCCVECTerminal"),
				},
				{
					path: "/settings/basicSettings/cahieroptions/edit/:id",
					name: "settings.basicSettings.cahieroptions.edit",
					meta: {
						siteTitle: "Cashier Options",
						menuTitle: "",
						layout: "default",
						icon: "",
						hideInNavigation: true,
						permission: {
							requiredModule: 0,
							requiredPermission: "manageMainSettings",
							hybridDisplay: "Server",
						},
					},
					component: () => import("../views/settings/sysetmdata/EditCashierOptions"),
				},
			],
		},
		{
			meta: {
				menuTitle: "settings.lang_modules",
				icon: "pe-7s-plugin",
				permission: {},
			},
			childs: [
				/*  {
                      path: '/settings/moduleSettings/modules',
                      name: 'settings.moduleSettings.modules',
                      meta: {
                          siteTitle: ('settings.lang_nav_modules'),
                          menuTitle: ('settings.lang_nav_modules'),
                          layout: 'default',
                          icon: '',
                          permission: {
                              requiredModule: 0,
                              requiredPermission: "set_modules",
                              hybridDisplay: "Server"
                          }
                      },
                      component: () => import('../views/common/404.vue')
                  },
                  {
                      path: '/settings/moduleSettings/ecTerminal',
                      name: 'settings.moduleSettings.ecTerminal',
                      meta: {
                          siteTitle: ('settings.lang_nav_ecterminal'),
                          menuTitle: ('settings.lang_nav_ecterminal'),
                          layout: 'default',
                          icon: '',
                          permission: {
                              requiredModule: 36,
                              requiredPermission: "",
                              hybridDisplay: "Server"
                          }
                      },
                      component: () => import('../views/common/404.vue')
                  },
                  {
                      path: '/settings/moduleSettings/prestashop',
                      name: 'settings.moduleSettings.prestashop',
                      meta: {
                          siteTitle: ('settings.lang_nav_prestashop'),
                          menuTitle: ('settings.lang_nav_prestashop'),
                          layout: 'default',
                          icon: '',
                          permission: {
                              requiredModule: 9,
                              requiredPermission: "",
                              hybridDisplay: "Server"
                          }
                      },
                      component: () => import('../views/common/404.vue')
                  },*/
				{
					path: "/settings/moduleSettings/vcita",
					name: "settings.moduleSettings.vcita",
					meta: {
						siteTitle: "VCITA",
						menuTitle: "VCITA",
						layout: "default",
						icon: "",
						permission: {
							requiredModule: 35,
							requiredPermission: "vcita_settings",
							hybridDisplay: "Server",
						},
					},
					component: () => import("../views/settings/moduleSettings/vcita.vue"),
				},
				{
					path: "/settings/moduleSettings/vmp",
					name: "settings.moduleSettings.vmp",
					meta: {
						siteTitle: "generic.lang_vmpsettings",
						menuTitle: "generic.lang_vmpsettings",
						layout: "default",
						icon: "",
						permission: {
							requiredModule: 8,
							requiredPermission: "vmp_settings",
							hybridDisplay: "Server",
						},
					},
					component: () => import("../views/settings/moduleSettings/vmp.vue"),
				},
				{
					path: "/settings/moduleSettings/apikey",
					name: "settings.moduleSettings.apikey",
					meta: {
						siteTitle: "generic.lang_publicAPI",
						menuTitle: "generic.lang_publicAPI",
						layout: "default",
						icon: "",
						permission: {
							requiredModule: 40,
							requiredPermission: "allowManageApiKeys",
							hybridDisplay: "Server",
						},
					},
					component: () => import("../views/settings/moduleSettings/APIKeys"),
				},
				{
					path: "/settings/moduleSettings/apikey/create",
					name: "settings.moduleSettings.apikey.create",
					meta: {
						siteTitle: "generic.lang_publicAPI",
						menuTitle: "generic.lang_publicAPI",
						layout: "default",
						icon: "",
						hideInNavigation: true,
						permission: {
							requiredModule: 40,
							requiredPermission: "allowManageApiKeys",
							hybridDisplay: "Server",
						},
					},
					component: () => import("../views/settings/moduleSettings/CreateAPIKey"),
				},
				{
					path: "/settings/moduleSettings/hs3",
					name: "settings.moduleSettings.hs3",
					meta: {
						siteTitle: "HS3",
						menuTitle: "HS3",
						layout: "default",
						icon: "",
						permission: {
							requiredModule: 44,
							requiredPermission: "hs3_settings",
							hybridDisplay: "Server",
						},
					},
					component: () => import("../views/settings/moduleSettings/HS3.vue"),
				},
				{
					path: "/settings/moduleSettings/ibelsa",
					name: "settings.moduleSettings.ibelsa",
					meta: {
						siteTitle: "IBELSA",
						menuTitle: "IBELSA",
						layout: "default",
						icon: "",
						permission: {
							requiredModule: 43,
							requiredPermission: "ibelsa_settings",
							hybridDisplay: "Server",
						},
					},
					component: () => import("../views/settings/moduleSettings/Ibelsa.vue"),
				},
				{
					path: "/settings/moduleSettings/suite8",
					name: "settings.moduleSettings.suite8",
					meta: {
						siteTitle: "Suite8",
						menuTitle: "Suite8",
						layout: "default",
						icon: "",
						permission: {
							requiredModule: 45,
							requiredPermission: "suite8_settings",
							hybridDisplay: "",
						},
					},
					component: () => import("../views/settings/moduleSettings/Suite8.vue"),
				},
				{
					path: "/settings/moduleSettings/sihot",
					name: "settings.moduleSettings.sihot",
					meta: {
						siteTitle: "Sihot",
						menuTitle: "Sihot",
						layout: "default",
						icon: "",
						permission: {
							requiredModule: 46,
							requiredPermission: "sihot_settings",
							hybridDisplay: "",
						},
					},
					component: () => import("../views/settings/moduleSettings/Sihot.vue"),
				},
				{
					path: "/settings/moduleSettings/dirmeier",
					name: "settings.moduleSettings.dirmeier",
					meta: {
						siteTitle: "DIRMEIER Schanktechnik",
						menuTitle: "DIRMEIER Schanktechnik",
						layout: "default",
						icon: "",
						permission: {
							requiredModule: 49,
							requiredPermission: "dirmeier_settings",
							hybridDisplay: "Server",
						},
					},
					component: () => import("../views/settings/moduleSettings/Dirmeier.vue"),
				},
				{
					path: "/settings/moduleSettings/strato",
					name: "settings.moduleSettings.strato",
					meta: {
						siteTitle: "ePages",
						menuTitle: "ePages",
						layout: "default",
						icon: "",
						permission: {
							requiredModule: 48,
							requiredPermission: "epages_settings",
							hybridDisplay: "Server",
						},
					},
					component: () => import("../views/settings/moduleSettings/Strato.vue"),
				},

				{
					path: "/settings/moduleSettings/woocommerce",
					name: "settings.moduleSettings.woocommerce",
					meta: {
						siteTitle: "Woocommerce api",
						menuTitle: "Woocommerce api",
						layout: "default",
						icon: "",
						permission: {
							requiredModule: 63,
							requiredPermission: "woocommerce_settings",
							hybridDisplay: "Server",
						},
					},
					component: () => import("../views/settings/moduleSettings/WoocommerceSettings.vue"),
				},

				{
					path: "/settings/moduleSettings/voffice",
					name: "settings.moduleSettings.voffice",
					meta: {
						siteTitle: "VOffice",
						menuTitle: "VOffice",
						layout: "default",
						icon: "",
						permission: {
							requiredModule: 51,
							requiredPermission: "voffice_settings",
							hybridDisplay: "Server",
						},
					},
					component: () => import("../views/settings/moduleSettings/Voffice.vue"),
				},

				{
					path: "/settings/moduleSettings/wolt",
					name: "settings.moduleSettings.wolt",
					meta: {
						siteTitle: "WOLT",
						menuTitle: "WOLT",
						layout: "default",
						icon: "",
						permission: {
							requiredModule: 79,
							requiredPermission: "wolt_settings",
							hybridDisplay: "Server",
						},
					},
					component: () => import("../views/settings/moduleSettings/Wolt.vue"),
				},
				{
					path: "/settings/moduleSettings/mergeport",
					name: "settings.moduleSettings.mergeport",
					meta: {
						siteTitle: "Mergeport",
						menuTitle: "Mergeport",
						layout: "default",
						icon: "",
						permission: {
							requiredModule: 81,
							requiredPermission: "mergeport_settings",
							hybridDisplay: "Server",
						},
					},
					component: () => import("../views/settings/moduleSettings/Mergeport.vue"),
				},
				{
					path: "/settings/moduleSettings/shopware",
					name: "settings.moduleSettings.shopware",
					meta: {
						siteTitle: "Shopware",
						menuTitle: "Shopware",
						layout: "default",
						icon: "",
						permission: {
							requiredModule: 77,
							requiredPermission: "shopware_settings",
							hybridDisplay: "Server",
						},
					},
					component: () => import("../views/settings/moduleSettings/Shopware.vue"),
				},
				{
					path: "/settings/moduleSettings/zmyle",
					name: "settings.moduleSettings.zmyle",
					meta: {
						siteTitle: "Zmyle",
						menuTitle: "Zmyle",
						layout: "default",
						icon: "",
						permission: {
							requiredModule: 58,
							requiredPermission: "zmyle_settings",
							hybridDisplay: "Server",
						},
					},
					component: () => import("../views/settings/moduleSettings/Zmyle.vue"),
				},
				{
					path: "/settings/moduleSettings/myworld",
					name: "settings.moduleSettings.myworld",
					meta: {
						siteTitle: "MyWorld",
						menuTitle: "MyWorld",
						layout: "default",
						icon: "",
						permission: {
							requiredModule: 57,
							requiredPermission: "myworld_settings",
							hybridDisplay: "Server",
						},
					},
					component: () => import("../views/settings/moduleSettings/MyWorld.vue"),
				},
				{
					path: "/settings/moduleSettings/tabakosApi",
					name: "settings.moduleSettings.tabakosApi",
					meta: {
						siteTitle: "Tabakos API",
						menuTitle: "Tabakos API",
						layout: "default",
						icon: "",
						permission: {
							requiredModule: 83,
							requiredPermission: "tabakos_api",
							hybridDisplay: "Server",
						},
					},
					component: () => import("../views/settings/moduleSettings/TabakosApi.vue"),
				},
				{
					path: "/settings/moduleSettings/scalesettings",
					name: "settings.moduleSettings.scalesettings",
					meta: {
						siteTitle: "settings.lang_navScaleSettings",
						menuTitle: "settings.lang_navScaleSettings",
						layout: "default",
						icon: "",
						permission: {
							requiredModule: 61,
							requiredPermission: "allowScaleSettings",
							hybridDisplay: "",
						},
					},
					component: () => import("../views/settings/moduleSettings/ScaleSettings.vue"),
				},
				{
					path: "/settings/moduleSettings/scalesettings/edit/:uuid",
					name: "settings.moduleSettings.scalesettings.edit",
					meta: {
						siteTitle: "settings.lang_navScaleSettings",
						menuTitle: "settings.lang_navScaleSettings",
						layout: "default",
						icon: "",
						hideInNavigation: true,
						permission: {
							requiredModule: 61,
							requiredPermission: "allowScaleSettings",
							hybridDisplay: "",
						},
					},
					component: () => import("../views/settings/moduleSettings/EditScaleSettings.vue"),
				},
				{
					path: "/settings/moduleSettings/scalesettings/create",
					name: "settings.moduleSettings.scalesettings.create",
					meta: {
						siteTitle: "settings.lang_navScaleSettings",
						menuTitle: "settings.lang_navScaleSettings",
						layout: "default",
						icon: "",
						hideInNavigation: true,
						permission: {
							requiredModule: 61,
							requiredPermission: "allowScaleSettings",
							hybridDisplay: "",
						},
					},
					component: () => import("../views/settings/moduleSettings/CreateScaleSettings.vue"),
				},

				{
					path: "/settings/moduleSettings/cashrecycler",
					name: "settings.moduleSettings.cashrecycler",
					meta: {
						siteTitle: "settings.lang_cashRecyclerSettings",
						menuTitle: "settings.lang_cashRecyclerSettings",
						layout: "default",
						icon: "",
						permission: {
							requiredModule: 72,
							requiredPermission: "cashdro_settings",
							hybridDisplay: "",
						},
					},
					component: () => import("../views/settings/moduleSettings/cashrecycler/CashRecyclerSettings"),
				},
				{
					path: "/settings/moduleSettings/cashrecycler/edit/:uuid",
					name: "settings.moduleSettings.cashrecycler.edit",
					meta: {
						siteTitle: "settings.lang_cashRecyclerSettings",
						menuTitle: "settings.lang_cashRecyclerSettings",
						layout: "default",
						icon: "",
						hideInNavigation: true,
						permission: {
							requiredModule: 72,
							requiredPermission: "cashdro_settings",
							hybridDisplay: "",
						},
					},
					component: () => import("../views/settings/moduleSettings/cashrecycler/EditCashRecyclerSettings"),
				},
				{
					path: "/settings/moduleSettings/cashrecycler/create",
					name: "settings.moduleSettings.cashrecycler.create",
					meta: {
						siteTitle: "settings.lang_cashRecyclerSettings",
						menuTitle: "settings.lang_cashRecyclerSettings",
						layout: "default",
						icon: "",
						hideInNavigation: true,
						permission: {
							requiredModule: 72,
							requiredPermission: "cashdro_settings",
							hybridDisplay: "",
						},
					},
					component: () => import("../views/settings/moduleSettings/cashrecycler/CreateCashRecyclerSettings"),
				},

				{
					path: "/settings/moduleSettings/paperlessinvoice",
					name: "settings.moduleSettings.paperless invoice",
					meta: {
						siteTitle: "settings.lang_paperlessInvoice",
						menuTitle: "settings.lang_paperlessInvoice",
						layout: "default",
						icon: "",
						permission: {
							requiredModule: 62,
							requiredPermission: "allowPaperlessInvoiceSettings",
							hybridDisplay: "Server",
						},
					},
					component: () => import("../views/settings/moduleSettings/PaperlessInvoice"),
				},
				{
					path: "/settings/moduleSettings/externalApps",
					name: "settings.moduleSettings.externalApps",
					meta: {
						siteTitle: "settings.lang_externalApps",
						menuTitle: "settings.lang_externalApps",
						layout: "default",
						icon: "",
						permission: {
							requiredModule: 65,
							requiredPermission: "edit_external_apps",
							hybridDisplay: "Server",
						},
					},
					component: () => import("../views/settings/moduleSettings/externalapps/ExternalAPPS.vue"),
				},
				{
					path: "/settings/moduleSettings/externalApps/edit/:uuid",
					name: "settings.moduleSettings.externalApps.edit",
					meta: {
						siteTitle: "settings.lang_externalapps",
						menuTitle: "settings.lang_externalapps",
						layout: "default",
						icon: "",
						hideInNavigation: true,
						permission: {
							requiredModule: 65,
							requiredPermission: "edit_external_apps",
							hybridDisplay: "Server",
						},
					},
					component: () => import("../views/settings/moduleSettings/externalapps/EditExternalAPPS.vue"),
				},
				{
					path: "/settings/moduleSettings/externalApps/create",
					name: "settings.moduleSettings.externalApps.create",
					meta: {
						siteTitle: "settings.lang_externalapps",
						menuTitle: "settings.lang_externalapps",
						layout: "default",
						icon: "",
						hideInNavigation: true,
						permission: {
							requiredModule: 65,
							requiredPermission: "add_external_apps",
							hybridDisplay: "Server",
						},
					},
					component: () => import("../views/settings/moduleSettings/externalapps/CreateExternalAPPS.vue"),
				},
				{
					path: "/settings/moduleSettings/webhooks",
					name: "settings.moduleSettings.webhooks",
					meta: {
						siteTitle: "settings.lang_webhooks",
						menuTitle: "settings.lang_webhooks",
						layout: "default",
						icon: "",
						permission: {
							requiredModule: 66,
							requiredPermission: "register_webhooks",
							hybridDisplay: "Server",
						},
					},
					component: () => import("../views/settings/moduleSettings/webhooks/Webhooks"),
				},
				{
					path: "/settings/moduleSettings/webhooks/edit/:uuid",
					name: "settings.moduleSettings.webhooks.edit",
					meta: {
						siteTitle: "settings.lang_webhooks",
						menuTitle: "settings.lang_webhooks",
						layout: "default",
						icon: "",
						hideInNavigation: true,
						permission: {
							requiredModule: 66,
							requiredPermission: "register_webhooks",
							hybridDisplay: "Server",
						},
					},
					component: () => import("../views/settings/moduleSettings/webhooks/EditWebhooks"),
				},
				{
					path: "/settings/moduleSettings/webhooks/create",
					name: "settings.moduleSettings.webhooks.create",
					meta: {
						siteTitle: "settings.lang_webhooks",
						menuTitle: "settings.lang_webhooks",
						layout: "default",
						icon: "",
						hideInNavigation: true,
						permission: {
							requiredModule: 66,
							requiredPermission: "register_webhooks",
							hybridDisplay: "Server",
						},
					},
					component: () => import("../views/settings/moduleSettings/webhooks/CreateWebhooks"),
				},
				{
					path: "/settings/moduleSettings/ratiotecPager",
					name: "settings.moduleSettings.ratiotecPager",
					meta: {
						siteTitle: "Ratiotec pager",
						menuTitle: "Ratiotec pager",
						layout: "default",
						icon: "",
						permission: {
							requiredModule: 69,
							requiredPermission: "ratiotecPager_settings",
							hybridDisplay: "Server",
						},
					},
					component: () => import("../views/settings/moduleSettings/RatiotecPager.vue"),
				},
				{
					path: "/settings/moduleSettings/tip-manager",
					name: "settings.moduleSettings.tipManager",
					meta: {
						siteTitle: "generic.lang_tipManager",
						menuTitle: "generic.lang_tipManager",
						layout: "default",
						icon: "",
						permission: {
							requiredModule: 2,
							requiredPermission: "manageMainSettings",
							hybridDisplay: "Server",
						},
					},
					component: () => import("../views/settings/moduleSettings/ModuleManagerView.vue"),
				},
				{
					path: "/settings/moduleSettings/discountManager",
					name: "settings.moduleSettings.discountManager",
					meta: {
						siteTitle: "generic.lang_discountManager",
						menuTitle: "generic.lang_discountManager",
						layout: "default",
						icon: "",
						permission: {
							requiredModule: 0,
							requiredPermission: "manageMainSettings",
							hybridDisplay: "Server",
						},
					},
					component: () => import("../views/settings/moduleSettings/DiscountManagerView.vue"),
				},
				{
					path: "/settings/moduleSettings/giftcardsale",
					name: "settings.moduleSettings.giftcardsale",
					meta: {
						siteTitle: "generic.lang_onlineGiftCardSale",
						menuTitle: "generic.lang_onlineGiftCardSale",
						layout: "default",
						icon: "",
						permission: {
							requiredModule: 0, //75,
							requiredPermission: "", //'giftcardsale_portal',
							hybridDisplay: "Server",
						},
					},
					component: () => import("../views/settings/moduleSettings/OnlineGiftCardSale.vue"),
				},
				{
					path: "/settings/moduleSettings/giftcardsale/cardtemplate/create",
					name: "settings.moduleSettings.giftcardsale.cardtemplate.create",
					meta: {
						hideInNavigation: true,
						siteTitle: "generic.lang_onlineGiftCardSale",
						menuTitle: "generic.lang_onlineGiftCardSale",
						layout: "default",
						icon: "",
						permission: {
							requiredModule: 0, //75,
							requiredPermission: "", //'giftcardsale_portal',
							hybridDisplay: "Server",
						},
					},
					component: () => import("../views/settings/moduleSettings/voucherbee/VoucherbeeCardTemplateCreate.vue"),
				},
				{
					path: "/settings/moduleSettings/giftcardsale/cardtemplate/edit/:id",
					name: "settings.moduleSettings.giftcardsale.cardtemplate.edit",
					meta: {
						hideInNavigation: true,
						siteTitle: "generic.lang_onlineGiftCardSale",
						menuTitle: "generic.lang_onlineGiftCardSale",
						layout: "default",
						icon: "",
						permission: {
							requiredModule: 0, //75,
							requiredPermission: "", //'giftcardsale_portal',
							hybridDisplay: "Server",
						},
					},
					component: () => import("../views/settings/moduleSettings/voucherbee/VoucherbeeCardTemplateEdit.vue"),
				},
				{
					path: "/settings/moduleSettings/smsGateway",
					name: "settings.moduleSettings.smsGateway",
					meta: {
						siteTitle: "settings.lang_smsGateway",
						menuTitle: "settings.lang_smsGateway",
						layout: "default",
						icon: "",
						permission: {
							requiredModule: 70,
							requiredPermission: "smsGateway_settings",
							hybridDisplay: "Server",
						},
					},
					component: () => import("../views/settings/moduleSettings/SMSGateway.vue"),
				},
				{
					path: "/settings/moduleSettings/accountUsage",
					name: "settings.moduleSettings.accountUsage",
					meta: {
						siteTitle: "settings.lang_accountUsage",
						menuTitle: "settings.lang_accountUsage",
						layout: "default",
						icon: "",
						permission: {
							requiredModule: 0,
							requiredPermission: "account_usage_overview",
							hybridDisplay: "",
						},
					},
					component: () => import("../views/settings/moduleSettings/AccountUsage.vue"),
				},
			],
		},
		{
			meta: {
				menuTitle: "settings.lang_navFiscalisation",
				icon: "pe-7s-culture",
				permission: {},
			},
			childs: [
				{
					path: "/settings/fiscalisation_de/fiscalDevice",
					name: "settings.fiscalisation_de.fiscalDevice",
					meta: {
						siteTitle: "settings.lang_fiscalDevice",
						menuTitle: "settings.lang_fiscalDevice",
						layout: "default",
						icon: "",
						permission: {
							requiredModule: [34, 74],
							requiredPermission: "tseDevices",
							hybridDisplay: "",
						},
					},
					component: () => import("../views/settings/fiscal_de/FiscalDevice.vue"),
				},
				{
					path: "/settings/fiscalisation_de/fiscalClient",
					name: "settings.fiscalisation_de.fiscalClient",
					meta: {
						siteTitle: "settings.lang_fiscalClients",
						menuTitle: "settings.lang_fiscalClients",
						layout: "default",
						icon: "",
						permission: {
							requiredModule: [34, 74],
							requiredPermission: "tseClients",
							hybridDisplay: "",
						},
					},
					component: () => import("../views/settings/fiscal_de/FiscalClient.vue"),
				},
				{
					path: "/settings/fiscalisation_de/dsfinvkExport",
					name: "settings.fiscalisation_de.dsfinvkExport",
					meta: {
						siteTitle: "DSFinV-K Export",
						menuTitle: "DSFinV-K Export",
						layout: "default",
						icon: "",
						permission: {
							requiredModule: 34,
							requiredPermission: "accounting_displaydsfunvkReports",
							hybridDisplay: "",
						},
					},
					component: () => import("../views/settings/fiscal_de/Export.vue"),
				},
				{
					path: "/settings/fiscalisation_de/fiscalExport",
					name: "settings.fiscalisation_de.fiscalExport",
					meta: {
						siteTitle: "Fiskal Export",
						menuTitle: "Fiskal Export",
						layout: "default",
						icon: "",
						permission: {
							requiredModule: [34, 74],
							requiredPermission: "tseExport",
							hybridDisplay: "",
						},
					},
					component: () => import("../views/settings/fiscal_de/TSEExport.vue"),
				},
			],
		},
	],
};
